<template>
  <div id="app" class="min-h-screen flex flex-col" @click="closeDropdown">
    <nav class="bg-gray-800 p-3">
      <div class="container mx-auto flex items-center justify-between">
        <a href="/" class="flex items-center text-white">
          <!-- <img src="/dio_head_blue.svg" alt="Logo" class="h-10 mr-2" /> -->
          Diodorus.ai
        </a>
        <ul class="flex items-center space-x-4">
          <li v-if="showAdminDash">
            <router-link to="/admin" class="text-white hover:underline">{{ currentUser.user.name }}</router-link>
          </li>
          <li v-if="currentUser" class="relative">
            <img src="/avatar_2x.png" class="h-8 w-8 rounded-full cursor-pointer" alt="user menu" @click.stop="toggleDropdown = !toggleDropdown"/>
            <div v-if="toggleDropdown" class="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
              <router-link v-if="showAdminDash" to="/admin" class="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">Admin</router-link>
              <router-link to="/user" class="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">Dashboard</router-link>
              <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">Account</router-link>
              <a href="#" @click="logOut" class="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">Logout</a>
            </div>
          </li>
          <li v-if="!currentUser">
            <router-link to="/register" class="text-white hover:underline">
              Register
            </router-link>
          </li>
          <li v-if="!currentUser">
            <router-link to="/login" class="text-white hover:underline">
              Login
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div class="container mx-auto flex-grow">
      <router-view />
    </div>

    <footer class="bg-gray-700 text-white text-center p-4 mt-auto">
      <span>&copy; 2024 Diodorus.ai &trade; All Rights Reserved </span>
<a @click="openDisc" class="text-xs text-gray-400 hover:text-white underline cursor-pointer">        Legal Disclaimer
      </a>
    </footer>
    <DisclaimerModal v-if="disclaimerOpen" @close="disclaimerOpen = false" />
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import EventBus from "./services/eventBus";
import DisclaimerModal from './components/DisclaimerModal.vue';

export default {
  components: { DisclaimerModal },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toggleDropdown = ref(false);
    const currentUser = computed(() => store.state.auth.user);
    const showAdminDash = computed(() => currentUser.value && currentUser.value.user.role === "admin");
    const disclaimerOpen = ref(false);

    const openDisc = () => {
      disclaimerOpen.value = true;
    };

    const logOut = () => {
      store.dispatch("auth/logout");
      router.push("/login");
      toggleDropdown.value = false;
    };

    const closeDropdown = (event) => {
      if (toggleDropdown.value && !event.target.closest(".relative")) {
        toggleDropdown.value = false;
      }
    };

    onMounted(() => {
      EventBus.on("logout", logOut);
    });

    onBeforeUnmount(() => {
      EventBus.off("logout", logOut);
    });

    return {
      toggleDropdown,
      currentUser,
      showAdminDash,
      logOut,
      closeDropdown,
      openDisc,
      disclaimerOpen,
    };
  },
};
</script>

<style>
/* Import Tailwind CSS in your project configuration */
@import "@/assets/tailwind.css";
</style>
