export default function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));

  // console.log(`USER LOCAL STORAGE => ${JSON.stringify(user, null, 2)}`);
  if (user && user.tokens.access) {
    return { Authorization: "Bearer " + user.tokens.access.token }; // for node back-end
  } else {
    return {};
  }
}
