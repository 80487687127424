import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/v1";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "/users/", { headers: authHeader() });
  }

  getUserDash(userID) {
    return axios.get(API_URL + "/users/" + userID, { headers: authHeader() });
  }

  getAdminDash() {
    return axios.get(API_URL + "/users/", { headers: authHeader() });
  }

  getUserPassword(data) {
    // console.log(`getUserPassword received: ${JSON.stringify(data)}`)
    return axios.post(API_URL + "/auth/forgot-password", data, {});
  }
  setUserPassword(data) {
    // console.log(`setUserPassword received: ${JSON.stringify(data)}`)
    return axios.post(
      API_URL + "/auth/reset-password?token=" + data.token,
      { password: data.password },
      {}
    );
  }

  getUserProfile(userID) {
    return axios.get(API_URL + "/users/" + userID, { headers: authHeader() });
  }

  updateUserProfile(userID, data) {
    return axios.put(API_URL + "/users/" + userID, data, {
      headers: authHeader(),
    });
  }

  addUser(data) {
    return axios.post(API_URL + "/users", data, {
      headers: authHeader(),
    });
  }

  deleteUser(userID) {
    return axios.delete(API_URL + "/users/" + userID, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
