import axios from "axios";
import authHeader from "./auth-header";
import TokenService from "./token.service";

// import moment from "moment";

import moment from "moment-timezone";

const API_URL = process.env.VUE_APP_API_URL + "/v1/auth/";

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        // console.log('API RESPONSE', JSON.stringify(response.data, null, 2))

        if (response.data.tokens.access) {
          localStorage.setItem("user", JSON.stringify(response.data));
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    TokenService.removeUser();
  }

  register(user) {
    const userTZ = moment.tz.guess();

    console.log(`User TimeZone = ${userTZ}`);

    if (!user.timezone || user.timezone === "") {
      user.timezone = userTZ;
    }

    return axios.post(
      API_URL + "register",
      {
        name: user.name,
        email: user.email,
        timezone: user.timezone,
        password: user.password,
      },
      { headers: authHeader() }
    );
  }
}

export default new AuthService();
