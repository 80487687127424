import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // Make sure this is Vuex 4

import setupInterceptors from "./services/setupInterceptors";

setupInterceptors(store);

import "./assets/tailwind.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  timeout: 5000,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toast, toastOptions);
app.mount("#app");
