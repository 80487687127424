<template>
    <div class="flex justify-center items-center min-h-screen bg-gray-100">
      <div class="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
        <h3 class="text-2xl font-bold mb-6 text-gray-800">Register New User</h3>
        <Form @submit="handleRegister" :validation-schema="schema" class="space-y-4">
          <div v-if="!successful">
            <div class="form-group">
              <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
              <Field name="name" type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              <ErrorMessage name="name" class="error-feedback mt-2 text-sm text-red-600" />
            </div>
            <div class="form-group">
              <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
              <Field name="email" type="email" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              <ErrorMessage name="email" class="error-feedback mt-2 text-sm text-red-600" />
            </div>
            <div class="form-group">
              <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
              <Field name="password" type="password" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              <ErrorMessage name="password" class="error-feedback mt-2 text-sm text-red-600" />
            </div>
            <div class="form-group">
              <button 
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                :disabled="loading"
              >
                <span v-show="loading" class="spinner-border spinner-border-sm mr-2"></span>
                Register
              </button>
            </div>
          </div>
        </Form>
        <div v-if="message" :class="['mt-4 p-4 rounded', successful ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800']">
          {{ message }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as yup from 'yup';
  import { useStore } from 'vuex';
  
  export default defineComponent({
    name: 'NewUser',
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    setup() {
      const store = useStore();
      const successful = ref(false);
      const loading = ref(false);
      const message = ref('');
      const schema = yup.object().shape({
        name: yup.string().min(3, 'Must be at least 3 characters!').max(20, 'Must be maximum 20 characters!'),
        email: yup.string().required('Email is required!').email('Email is invalid!').max(50, 'Must be maximum 50 characters!'),
        password: yup.string().required('Password is required!').min(6, 'Must be at least 6 characters!').max(40, 'Must be maximum 40 characters!'),
      });
  
      const handleRegister = async (user) => {
        message.value = '';
        successful.value = false;
        loading.value = true;
  
        try {
          const res = await store.dispatch('auth/register', user);
          const userName = res.user.name;
          const userEml = res.user.email;
          const messageText = `Successfully registered ${userName} <${userEml}>.\nTOKENs: ${JSON.stringify(res.tokens)}`;
          message.value = messageText;
          successful.value = true;
        } catch (error) {
          message.value = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          successful.value = false;
        } finally {
          loading.value = false;
        }
      };
  
      return {
        successful,
        loading,
        message,
        schema,
        handleRegister,
      };
    },
  });
  </script>
  
  <style scoped>
  .error-feedback {
    color: red;
  }
  
  .spinner-border {
    border: 0.1em solid rgba(0, 0, 0, 0.1);
    border-right-color: transparent;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation: spin 0.75s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  </style>
  