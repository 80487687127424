<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Legal Disclaimer
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  This prototype is a cutting-edge software platform, presented to you by <strong>Diodorus.ai</strong> ("Diodorus &trade;"). It is provided "as is" without any warranties, either express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. By using this prototype, you acknowledge that it is a work in progress and may contain bugs or inaccuracies.
                </p>
                <p class="text-sm text-gray-500 mt-2">
                  <strong>Diodorus.ai</strong> shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if <strong>Diodorus.ai</strong> has been advised of the possibility of such damages), resulting from the use or the inability to use the prototype.
                </p>
                <p class="text-sm text-gray-500 mt-2">
                  Any feedback or suggestions you provide regarding this prototype are entirely voluntary and <strong>Diodorus.ai</strong> is free to use such feedback or suggestions as it sees fit and without any obligation to you.
                </p>
                <p class="text-sm text-gray-500 mt-2">
                  Please note that you are not authorized to share, distribute, or disclose any information about this prototype to any third parties without explicit permission from <strong>Diodorus.ai</strong>. By proceeding, you agree to keep all aspects of this prototype confidential.
                </p>
                <p class="text-sm text-gray-500 mt-2">
                  We appreciate your understanding and cooperation as we continue to develop and improve this exciting new technology.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="$emit('close')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],
};
</script>

<style scoped>
</style>
