import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import NewUser from "./components/NewUser.vue";
import PasswordGet from "./components/PasswordGet.vue";
import PasswordReset from "./components/PasswordReset.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue");
const DashAdmin = () => import("./components/DashAdmin.vue");
const DashUser = () => import("./components/DashUser.vue");
const Project = () => import("./components/Project.vue");
const Bot = () => import("./components/Bot.vue");
const Chat = () => import("./components/Chat.vue");
const NotFound = () => import("./components/NotFound.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/newuser",
    component: NewUser,
  },

  {
    path: "/register",
    component: Register,
  },

  {
    path: "/forgot",
    name: "forgot",
    component: PasswordGet,
  },
  {
    path: "/reset",
    name: "reset",
    component: PasswordReset,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },

  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: DashAdmin,
  },

  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: DashUser,
  },
  {
    path: "/project/:id",
    name: "project",
    component: Project,
    props: true,
  },

  {
    path: "/bot/:id",
    name: "Bot",
    component: Bot,
    props: true,
  },

  {
    path: "/:projectName/:botSlug",
    name: "Chat",
    component: Chat,
    props: true,
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/forgot", "/reset"];
  //No public chats yet!
  // const authRequired = !publicPages.includes(to.path) && to.name !== "Chat";
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (to.path === "/login" && loggedIn) {
    next("/user");
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   const privatePages = [
//     "/profile",
//     "/admin",
//     "/user",
//     "/project/:id",
//     "/bot/:id",
//     "/:projectName/:botSlug",
//   ];
//   const authRequired = privatePages.includes(to.path);
//   const loggedIn = localStorage.getItem("user");

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next("/login");
//   } else if (to.path === "/login" && loggedIn) {
//     next("/user");
//   } else {
//     next();
//   }
// });

export default router;
