<template>
    <div class="flex items-center justify-center min-h-screen bg-gray-100">
        <div class="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden p-10">
            <div class="mb-8 text-center">
                <h2 class="text-3xl font-semibold text-gray-700">Register</h2>
            </div>
            <Form @submit="handleRegister" class="space-y-6" v-if="!successful">
                <div>
                    <label for="name" class="text-sm font-medium text-gray-600 block mb-2">Name</label>
                    <Field v-model="name" name="name" type="text" placeholder="Your name" class="w-full px-4 py-3 rounded-lg bg-gray-50 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" />
                    <ErrorMessage name="name" class="text-red-500 text-xs mt-1" />
                </div>
                <div>
                    <label for="email" class="text-sm font-medium text-gray-600 block mb-2">Email</label>
                    <Field v-model="email" name="email" type="email" placeholder="Your email" class="w-full px-4 py-3 rounded-lg bg-gray-50 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" />
                    <ErrorMessage name="email" class="text-red-500 text-xs mt-1" />
                </div>
                <div>
                    <label for="password" class="text-sm font-medium text-gray-600 block mb-2">Password</label>
                    <div class="relative">
                        <Field v-model="password" :type="showPassword ? 'text' : 'password'" name="password" placeholder="Your password" class="w-full px-4 py-3 rounded-lg bg-gray-50 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" />
                        <button type="button" @click="showPassword = !showPassword" class="absolute inset-y-0 right-0 pr-3 flex items-center">
                            <svg
                                class="h-6 w-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g v-if="showPassword">
                                <!-- Stylized Eye Open Icon -->
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1.5 12C1.5 12 5 4.5 12 4.5C19 4.5 22.5 12 22.5 12C22.5 12 19 19.5 12 19.5C5 19.5 1.5 12 1.5 12Z"
                                ></path>
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="3"
                                    stroke="currentColor"
                                    stroke-width="2"
                                ></circle>
                                </g>
                                <g v-else>
                                <!-- Stylized Eye Closed Icon -->
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M2 12C2 12 6 3 12 3C18 3 22 12 22 12"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M2 12C2 12 6 21 12 21C18 21 22 12 22 12"
                                ></path>
                                <line
                                    x1="1"
                                    y1="1"
                                    x2="23"
                                    y2="23"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                ></line>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <ErrorMessage name="password" class="text-red-500 text-xs mt-1" />
                </div>
                <div>
                    <button type="submit" :disabled="isSubmitting" class="w-full block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6">Register</button>
                </div>
            </Form>
            <p class="mt-8 text-sm text-center text-gray-400">
                Already have an account? <a href="#" @click="$router.push('/login')" class="text-blue-500 hover:text-blue-700">Login here</a>
            </p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage, useForm, useField } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as yup from 'yup';

export default {
    name: "Register",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    setup() {
        const successful = ref(false);
        const loading = ref(false);
        const showPassword = ref(false);
        const message = ref("");
        const { handleSubmit, isSubmitting } = useForm();
        const store = useStore();
        const router = useRouter();

        const { value: name, errorMessage: nameError } = useField('name', yup.string().required().min(3).max(20));
        const { value: email, errorMessage: emailError } = useField('email', yup.string().required().email().max(50));
        const { value: password, errorMessage: passwordError } = useField('password', yup.string().required().min(6).max(40));

        onMounted(() => {
            if (store.state.auth.status.loggedIn) {
                router.push("/profile");
            }
        });

        const handleRegister = handleSubmit(user => {
            message.value = "";
            successful.value = false;
            loading.value = true;

            store.dispatch("auth/register", user).then(
                (data) => {
                    message.value = data;
                    successful.value = true;
                    loading.value = false;
                },
                (error) => {
                    message.value =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    successful.value = false;
                    loading.value = false;
                }
            );
        });

        return {
            successful,
            loading,
            showPassword,
            message,
            handleRegister,
            name,
            email,
            password,
            nameError,
            emailError,
            passwordError,
            isSubmitting
        };
    },
};
</script>

<style scoped>
label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.error-feedback {
    color: red;
}
</style>