<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden p-10">
      <div class="mb-8 text-center">
        <h2 class="text-3xl font-semibold text-gray-700">Login</h2>
      </div>
      <Form @submit="handleLogin" class="space-y-6">
        <div>
          <label for="email" class="text-sm font-medium text-gray-600 block mb-2">Email</label>
          <Field name="email" type="email" placeholder="Your email" class="w-full px-4 py-3 rounded-lg bg-gray-50 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" />
          <ErrorMessage name="email" class="text-red-500 text-xs mt-1" />
        </div>
        <div>
          <label for="password" class="text-sm font-medium text-gray-600 block mb-2">Password</label>
          <Field name="password" type="password" placeholder="Your password" class="w-full px-4 py-3 rounded-lg bg-gray-50 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" />
          <ErrorMessage name="password" class="text-red-500 text-xs mt-1" />
        </div>
        <div>
          <button type="submit" :disabled="loading" class="w-full block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6">Login</button>
        </div>
      </Form>
      <p class="mt-8 text-sm text-center text-gray-400">
        Forgot your password? <a href="#" @click="$router.push('/forgot')" class="text-blue-500 hover:text-blue-700">Reset it</a>
      </p>
    </div>
  </div>
</template>




<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().email().required("Email is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/user");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/user");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>


