<template>
  <div class="container">
    <header class="jumbotron">
      <h3>TBA</h3>

      
      <!-- {{ content }} -->
      <div class="">
    <h4><a href="/docs/" target="_blank">Docs</a></h4>
  </div>
      

    </header>
  </div>

</template>

<script>
import UserService from "../services/user.service";

export default {
    name: "Home",
    data() {
        return {
            content: "",
        };
    },
    mounted() {
        UserService.getPublicContent().then((response) => {
            this.content = response.data;
        }, (error) => {
            this.content =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
        });
    },
};
</script>
