<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden p-10">
      <Form @submit="setPassword" class="space-y-6" :validation-schema="schema" v-if="!successful">
        <!-- Hidden Token Field -->
        <Field name="token" type="hidden" v-model="changeToken" />
        
        <div>
          <label for="password" class="text-sm font-medium text-gray-600 block mb-2">New Password:</label>
          <Field name="password" type="password" placeholder="Enter new password" class="w-full px-4 py-3 rounded-lg bg-gray-50 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" />
          <ErrorMessage name="password" class="text-red-500 text-xs mt-1" />
        </div>

        <div>
          <button :disabled="loading" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <span v-show="loading" class="spinner-border spinner-border-sm mr-2"></span>
            Reset Password!
          </button>
        </div>
      </Form>

      <div v-if="message" :class="{ 'bg-green-100 text-green-800': successful, 'bg-red-100 text-red-800': !successful }" class="mt-4 p-4 rounded-lg" role="alert">
        {{ message }}
      </div>
      <div class="mt-4 text-center">
        <a href="/login" class="text-blue-500 hover:text-blue-700">Back to Login</a>
      </div>
    </div>
  </div>
</template>


<script>
// import { useRoute } from 'vue-router';
// const route = useRoute();

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";

export default {
  name: "PasswordReset",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      token: yup.string().required("Reset token is required!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 8 characters!")
        .max(40, "Must be maximum 40 characters!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      changeToken: "",
      schema,
    };
  },

  methods: {
    setPassword(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      UserService.setUserPassword(user).then(
        (res) => {
          this.message = res.data["message"];
          this.successful = true;
          this.loading = false;
          this.redirectToLogin();
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },

    redirectToLogin() {
      this.$router.push({ name: "login" });
    },
  },

  mounted() {
    const params = new URL(document.location).searchParams;
    const resetToken = params.get("token");
    console.log(`resetToken: ${resetToken}`);
    this.changeToken = resetToken;
    // let testRunData = {}
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
